/* Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  padding: 5px 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  margin-bottom: 5%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  color: #fff;
  font-size: 1.5em;
  text-decoration: none;
}

/* Posizionamento e dimensionamento del menu-icon */
.menu-icon {
  display: none;
  font-size: 1.5em;
  color: #fff;
  cursor: pointer;
  margin-right: 20px; /* Sposta il bottone a sinistra rispetto al bordo */
}

.navbar-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-item {
  margin-left: 20px;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  display: block;
  padding: 6px 20px;
  transition: background 0.3s ease;
}

.navbar-link:hover {
  background-color: #555;
}

/* Side Drawer */
/* .navbar-menu.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #333;
  flex-direction: column;
  padding-top: 60px;
  z-index: 2000;
} */

.navbar-item {
  margin: 0px 0;
}

.navbar-link {
  width: 100%;
  text-align: left;
}

/* Sfondo scuro dietro la barra laterale */
/* .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1500;
} */

/* Media Queries per dispositivi mobili */
/* @media (max-width: 768px) {
  .menu-icon {
    display: block;
    margin-right: 30px; 
  }

  .navbar-menu {
    display: none;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-container {
    justify-content: space-between;
  }
} */
