@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-stroke {
  -webkit-text-stroke: 1px #772ce8;
  color: white;
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, #49a09d, #5f2c82);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #333;
}

.predictionsMenu {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 43px 0;
}

.container {
  position: absolute;
  top: calc(43px + 150px);
  left: 50%;
  max-height: 600px;
  transform: translate(-50%, 0);
  min-height: 400px;
}

@media (max-width: 1468px) {
  .container {
    top: calc(43px + 150px); 
    left: 15%; 
    transform: translate(0, 0); 
    width: 70vw; 
    padding: 0 10px; 
  }
}

@media (max-width: 1068px) {
  .container {
    top: calc(43px + 150px); 
    left: 0; 
    transform: translate(0, 0); 
    width: 100vw; 
    padding: 0 10px; 
  }
}

table {
  width: 100%; 
  height: 100px;
  border-collapse: collapse; 
  overflow: hidden;  
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
th {
  text-align: center;
}
thead th {
  background: linear-gradient(135deg, rgba(85, 96, 143, 0.9), rgba(85, 96, 143, 0.6));
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  position: relative;
}
/* tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -1px; 
  bottom: -1px;
  background-color: rgba(255, 255, 255, 0.2);
} */

tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1000; 
}
.cell-text {
  font-weight: bold;
  color: black;
}

@media (max-width: 768px) {
  .optionalField {
    display: none;
  }
}

@media (max-width: 768px) {
  table {
    width: 100%;
    height: auto;
  }

  th, td {
    padding: 10px; 
    font-size: 12px; 
  }
}


/* button {
  display: inline-block;
  outline: 0;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  height: 33px;
  background-color: #9147ff;
  color: white;
  padding: 0 20px;
} */

.last5container {
  display: flex;
  justify-content: center;
  font-size: 13px;
  width: 1100px;
}

@media (max-width: 768px) {
  .left-table, .right-table {
    width: calc(100% - 20px); /* Riduci la larghezza per adattarla ai dispositivi mobili */
    font-size: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.left-table, .right-table {
  flex: 1 1 calc(50% - 10px); /* Permette alle tabelle di occupare metà della larghezza del contenitore */
  min-width: 0; /* Previene che le tabelle si restringano troppo */
}

.center-table {
  font-size: 12px;
  width: calc(50% - 2.5px); /* Riduci lo spazio tra le tabelle a 2.5px */
  padding-right: 20px;
  padding-left: 20px;
}

.table-container {
  font-size: 11.5px;
  margin-bottom: 10px; /* Riduci lo spazio tra le tabelle verticalmente a 10px */
}

.biggerFont{
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

.last5container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
}

.last5container th,
.last5container td {
  padding: 3px;
}



.dark-red {
  background: linear-gradient(135deg, rgba(183, 28, 28, 0.9), rgba(183, 28, 28, 0.6));
}

.medium-red {
  background: linear-gradient(135deg, rgba(210, 35, 35, 0.9), rgba(210, 35, 35, 0.6));
}

.light-red {
  background: linear-gradient(135deg, rgba(243, 85, 53, 0.9), rgba(243, 85, 53, 0.6));
}

.dark-orange {
  background: linear-gradient(135deg, rgba(255, 115, 0, 0.9), rgba(255, 115, 0, 0.6));
}

.light-orange {
  background: linear-gradient(135deg, rgba(255, 170, 0, 0.9), rgba(255, 170, 0, 0.6));
}

.dark-yellow {
  background: linear-gradient(135deg, rgba(255, 193, 7, 0.9), rgba(255, 193, 7, 0.6));
}

.light-yellow {
  background: linear-gradient(135deg, rgba(235, 255, 59, 0.9), rgba(235, 255, 59, 0.6));
}

.light-green {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.9), rgba(76, 175, 80, 0.6));
}

.medium-green {
  background: linear-gradient(135deg, rgba(61, 161, 66, 0.9), rgba(61, 161, 66, 0.6));
}

.dark-green {
  background: linear-gradient(135deg, rgba(24, 124, 31, 0.9), rgba(24, 124, 31, 0.6));
}



.AnalysisContainer {
  position: absolute;
  left: 50%;
  max-width: 100%;
  max-height: 600px;
  transform: translateX(-50%); 
  margin-top: 30px;
}

.LastMatchesAndResultPrevision {
  padding-top: 6%; 
}

